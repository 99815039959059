



/* Container for the entire component */
.overview-container,
.next-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    transition: background-color 0.1s ease-in-out;
}



.typewriter p {
    font-size: 16px; /* Adjusted font size for better visibility */
    overflow: hidden;
    color: #61c482;
    font-family: monospace;
    border-right: .15em solid rgb(65, 104, 211); /* The typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Centers the text */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 5.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  .typewriter{
    font-size: 16px; /* Adjusted font size for better visibility */
    overflow: hidden;
    color: #2f4b52;
    font-family: monospace;
    border-right: .15em solid rgb(65, 104, 211); /* The typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Centers the text */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 2.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: rgb(65, 104, 211); }
  }
  @media (max-width: 767px) {
    .typewriter{
      font-size: 24px; /* Adjust the font size for smaller screens */
    }
   p {
      font-size: 1.5rem; /* Ajustez la taille pour les petits écrans */
    }
  
    h2 {
      font-size: 0.9rem; /* Ajustez la taille pour les petits écrans */
    }
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .animate-bounce {
    animation: bounce 2s infinite;
  }
  .arrow-icon path {
    fill: rgb(126, 126, 126); /* Default color for light mode */
  }
  .dark-mode .arrow-icon path {
    fill: rgb(209, 199, 199); /* Color for dark mode */
  }
/* Dark mode styles */
.dark-mode {
    background-color: #1e1e1e; /* Dark background */
}

.dark-mode.next-section {
    background-color: #363636; /* Dark background for the next section */
}

/* Arrow Button */
.arrow-button {
    position: fixed; /* Change to fixed positioning */
    bottom: 130px; /* Position at the bottom */
    right: 20px; /* Position on the right */
    padding: 30px;
    font-size: 3em;
    color: rgb(72, 98, 182); /* Default color */
    background-color: transparent; /* Transparent background */
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}

/* Hover effects for arrow buttons */
.arrow-button:hover {
    color: #24a87f; /* Change color on hover */
}
.arrow-button-section2 {
    position: absolute; /* Absolute positioning */
    bottom: 90px; /* Position at the bottom */
    right: 20px; /* Position on the right */
    padding: 30px;
    font-size: 4em;
    color: rgb(22, 21, 21); /* Default color */
    background-color: transparent; /* Transparent background */
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}
/* Styles for the next section text */
.next-section h1 {
    font-size: 5.5em;
    margin: 10px 0;
    text-align: center;
}

.next-section p {
    font-size: 2em;
    text-align: center;
    margin: 10px 0;
}

/* Additional styling for sub-text */
.sub-text {
    font-size: 1.2em;
    color: #1d1d1d; /* Slightly muted color for sub-text */
}


/* Dark Mode Adjustments */
.dark-mode .next-section h1,
.dark-mode .next-section p {
    color: #fff; /* White text for dark mode */
}

.dark-mode .sub-text {
    color: #ccc; /* Light gray for dark mode */
}

/* Styling for the image */
.glasses-image {
    width: 450px;
    margin-bottom: 50px;
    transition: filter 0.3s ease-in-out;
}

/* Text container */
.text-wrapper {
    text-align: center;
    margin-bottom: 20px;
}

/* Paragraph styling */
.animated-text {
    font-size: 2.5em;
    margin: 10px 0;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    font-weight: bold;
    color: #a8a6a6; /* Default text color for light mode */
}

/* Dark mode text colors */
.dark-mode .animated-text {
    color: #fff; /* White text for dark mode */
}

.animated-text:nth-child(2) {
    color: #24a87f;
    font-size: 1.5em;
}

.dark-mode .animated-text:nth-child(2) {
    color: #98ffda; /* Light green text for dark mode */
}

/* Button styling */
.start-button {
    padding: 15px 30px;
    font-size: 1.5em;
    background-color: #24a87f;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.start-button:hover {
    background-color: #1f8c6a;
}

.dark-mode .start-button {
    background-color: #3bb391;
}

/* Divider for text sections */
.divider {
    width: 80%;
    border: 1px solid #ccc;
    margin-bottom: 30px;
}

.dark-mode .divider {
    border-color: #555; /* Lighter divider in dark mode */
}

/* Next section styling */
.next-section {
    text-align: center;
    padding: 50px 20px;
}

.next-section h1 {
    font-size: 2.5em;
    color: #333;
}

.next-section .green-text {
    color: #24a87f;
}

.dark-mode .next-section h1,
.dark-mode .next-section p {
    color: white;
}

.dark-mode .green-text {
    color: #98ffda;
}

/* Sub-text styling */
.sub-text {
    font-size: 1.2em;
    color: #777;
}

.dark-mode .sub-text {
    color: #bbb;
}




/* Responsive styles */
@media (max-width: 768px) {
    .overview-container, .next-section {
      padding: 10px;
    }
  
    .typewriter p, .typewriter {
      font-size: 14px;
    }
  
    .glasses-image {
      width: 80%;
      height: auto;
    }
  
    .text-wrapper h1 {
      font-size: 1.5em;
    }
  
    .start-button {
      font-size: 1em;
      padding: 10px 20px;
    }
  
    .arrow-button {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .overview-container, .next-section {
      padding: 5px;
    }
  
    .typewriter p, .typewriter {
      font-size: 12px;
    }
  
    .glasses-image {
      width: 100%;
      height: auto;
    }
  
    .text-wrapper h1 {
      font-size: 1.2em;
    }
  
    .start-button {
      font-size: 0.8em;
      padding: 8px 16px;
    }
  
    .arrow-button {
      font-size: 1.2em;
    }
  }

  /*third section*/








.dark-mode .green-text {
    color: #98ffda;
}

/* Sub-text styling */
.sub-text {
    font-size: 1.2em;
    color: #777;
}

.dark-mode .sub-text {
    color: #bbb;
}



/*third section*/
/* third Section Styling */
.overview-container,.third-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  transition: background-color 0.1s ease-in-out;
}

.third-section-title{
  font-size: 2.5em;
  color: #18A558; /* Green color for title */
  font-weight: bold;
  margin-bottom: 15px;
}

.third-section-subtitle {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
}

.third-section-content {
  font-size: 1.6em;
  color: #3a3a3a;
  max-width: 800px;
  margin: 0 auto;
}

.dark-mode .third-section {
  background-color: #111;
}

.dark-mode .third-section-title,
.dark-mode .third-section-subtitle,
.dark-mode .third-section-content {
  color: #e0e0e0;
}







/*fourth section*/
/* Fourth Section Styling */
.overview-container,.fourth-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  transition: background-color 0.1s ease-in-out;
}

.fourth-section-title {
  font-size: 2.5em;
  color: #18A558; /* Green color for title */
  font-weight: bold;
  margin-bottom: 15px;
}

.fourth-section-subtitle {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
}

.fourth-section-content {
  font-size: 1.6em;
  color: #3a3a3a;
  max-width: 800px;
  margin: 0 auto;
}

.dark-mode .fourth-section {
  background-color: #111;
}

.dark-mode .fourth-section-title,
.dark-mode .fourth-section-subtitle,
.dark-mode .fourth-section-content {
  color: #ece2e2;
}





/*fifth section*/
/* Fifth Section Styling */
.overview-container,.fifth-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  transition: background-color 0.1s ease-in-out;
}

.fifth-section-title {
  font-size: 2.5em;
  color: #18A558; /* Green color for title */
  font-weight: bold;
  margin-bottom: 15px;
}

.fifth-section-subtitle {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
}

.fifth-section-content {
  font-size: 1.2em;
  color: #e0e0e0;
  max-width: 800px;
  margin: 0 auto;
}

.dark-mode .fifth-section {
  background-color: #111;
}

.dark-mode .fifth-section-title,
.dark-mode .fifth-section-subtitle,
.dark-mode .fifth-section-content {
  color: #e0e0e0;
}




/* Responsive styles */
@media (max-width: 768px) {
    .overview-container, .third-section {
      padding: 10px;
    }
  
    .typewriter p, .typewriter {
      font-size: 14px;
    }
  
    .glasses-image {
      width: 80%;
      height: auto;
    }
  
    .text-wrapper h1 {
      font-size: 1.5em;
    }
  
    .start-button {
      font-size: 1em;
      padding: 10px 20px;
    }
  
    .arrow-button {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .overview-container, .third-section {
      padding: 5px;
    }
  
    .typewriter p, .typewriter {
      font-size: 12px;
    }
  
    .glasses-image {
      width: 100%;
      height: auto;
    }
  
    .text-wrapper h1 {
      font-size: 1.2em;
    }
  
    .start-button {
      font-size: 0.8em;
      padding: 8px 16px;
    }
  
    .arrow-button {
      font-size: 1.2em;
    }
  }



  .custom-hr {
    border: none;
    border-top: 1px solid #727272;
  }



/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .overview-container, .next-section, .third-section, .fourth-section, .fifth-section {
    padding: 10px;
  }
  .glasses-image {
    width: 80%;
  }
  .start-button {
    font-size: 1em;
  }
  .arrow-button {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .overview-container, .next-section, .third-section, .fourth-section, .fifth-section {
    padding: 5px;
  }
  .glasses-image {
    width: 100%;
  }
  .start-button {
    font-size: 0.8em;
    padding: 8px 16px;
  }
  .arrow-button {
    font-size: 1.2em;
  }
}
/* Responsive adjustments */
@media (max-width: 1200px) {
  .typewriter p {
    font-size: 14px; /* Adjust font size for large screens */
  }
}

@media (max-width: 992px) {
  .typewriter p {
    font-size: 12px; /* Adjust font size for medium screens */
  }
}

@media (max-width: 768px) {
  .typewriter p {
    font-size: 10px; /* Adjust font size for small screens */
  }
}

@media (max-width: 576px) {
  .typewriter p {
    font-size: 8px; /* Adjust font size for extra small screens */
  }
}