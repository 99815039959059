.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #f7f9f6; /* Updated background color */

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes button-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-button {
  background-color: transparent;
  border: 2px solid #61c482;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
 
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;
  animation: button-rotate 2s linear infinite;
  margin: 0 auto; /* Center the button horizontally */

}

.toggle-button.light {
  background-color: #f7f9f6;
}

.toggle-button.dark {
  background-color: #333;
}

.toggle-button:hover {
  transform: scale(1.1);
  width: 45px;
  height: 45px;
}

.App-logo {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}